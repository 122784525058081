<!--合包列表-->
<template>
  <div class="container">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
      <el-tab-pane label="合包列表" name="first">
        <!-- 按钮列表 -->
        <div class="btnList1 wtl_btnList" ref="btnList">
          <div class="btnList1 wtl_btnList1">
            <el-button class="itemBtn btnColor" @click="addHeBao"
              >新建合包</el-button
            >
            <el-button class="itemBtn btnColor" @click="chaiBao"
              >拆包</el-button
            >
            <el-button class="itemBtn btnColor" @click="editBtns"
              >修改</el-button
            >
            <el-button class="itemBtn btnColor" @click="heBaoChuKu"
              >合包出库</el-button
            >
            <el-button class="itemBtn btnColor" @click="heBaoRuKu"
              >合包入库</el-button
            >

            <el-button class="itemBtn btnColor" @click="myModel"
              >表格设置</el-button
            >
            <el-dropdown @command="handleCommand_dc">
              <el-button class="dropdownBtn">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="dc">导出</el-dropdown-item>
                <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
                <el-dropdown-item command="scmb"
                  >保存(上传)模板</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            class="singleinpbtn wtl_queryBoxNew"
            v-if="hasPermiss('dbck_jianHuoXiaJia:query')"
          >
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="resetFeild">清除</div>
            </el-tooltip>
            <!-- <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip> -->
          </div>
        </div>
        <!-- 查询条件 -->
        <el-form :model="queryData_hb">
          <div
            ref="queryCriteria"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <div class="singleinp wtlTimeBox">
                <div class="singleinpleft wtlTimeLabel">合包时间:</div>
                <div class="wtl_timeStyle">
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd"
                    v-model="queryData_hb.startPackingTime"
                    type="date"
                    placeholder="选择日期"
                  ></el-date-picker>
                  <div>至</div>
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd"
                    v-model="queryData_hb.endPackingTime"
                    type="date"
                    placeholder="选择日期"
                    @change="queryBtn_ok"
                  ></el-date-picker>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">打包批次号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="billPackNumbers"
                    placeholder="请输入"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">合包单号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryData_hb.billClosePackageNumber"
                    placeholder="请输入"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">当前仓库:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData_hb.currentStorageId"
                    filterable
                    placeholder="请选择"
                    @change="queryBtn_ok"
                  >
                    <el-option
                      v-for="item in storageArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </el-form>
        <!-- 表格 -->
        <el-table
          :height="tableHeigth"
          stripe
          ref="mytable"
          @row-click="rowclick"
          :header-cell-style="{ background: '#f0f0f0' }"
          :data="tableData"
          border
          show-summary
          :summary-method="getSummaries"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed="left"
            align="center"
            label="序号"
            type="index"
            width="55"
          >
          </el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            type="selection"
            width="55"
          >
          </el-table-column>
          <template v-for="(item, index) in myTableHeard">
            <el-table-column
              :key="index"
              align="center"
              :show-overflow-tooltip="true"
              sortable
              :label="item.name"
              :prop="item.field"
              :min-width="item.width"
              :data-isTotal="item.isTotal"
              v-if="item && item.isShow"
            >
            </el-table-column>
          </template>
        </el-table>
        <paging
          :pageNum="pageNum"
          :total="total"
          :sizeList="sizeList"
          :size="size"
          @handleSizeChange="HSC_SJ"
          @handleCurrentChange="HCC_SJ"
        >
        </paging>

        <sorttable
          :show_sortTableHeard="show_sortTableHeard"
          :allCheck="allCheck"
          :sortName="sortName"
          :tabelHeadeTitle="tabelHeadeTitle"
          :originalTabelHeadeTitle="originalTabelHeadeTitle"
          :selectLength="selectLength"
          @cancelSortHeard="cancelSortHeard"
          @confirmOk="confirmOk"
          @checktab="checktab"
          @checkAll="checkAll"
          @closeDias="closeDias"
        ></sorttable>
        <!-- 分页组件 -->

        <el-dialog
          title="上传编辑好的导出模板"
          :visible.sync="show_upload"
          append-to-body
          width="40%"
          center
        >
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>

          <div slot="footer" class="dialog-footer">
            <el-button @click="show_upload = false">关 闭</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="合包明细" name="second">
        <div class="btnList1 wtl_btnList">
          <div class="btnList1 wtl_btnList1">
            <el-button class="itemBtn btnColor" @click="myModel1"
              >表格设置</el-button
            >
            <el-dropdown @command="handleCommand_dc">
              <el-button class="dropdownBtn">
                导出<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="dc">导出</el-dropdown-item>
                <el-dropdown-item command="dcmb">下载导出模板</el-dropdown-item>
                <el-dropdown-item command="scmb"
                  >保存(上传)模板</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div
            class="singleinpbtn wtl_queryBoxNew"
            v-if="hasPermiss('dbck_jianHuoXiaJia:query')"
          >
            <el-button
              class="seniorbut"
              @click="queryBtn_ok"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-tooltip content="查询项恢复初始状态" placement="top">
              <div class="condition" @click="resetFeild">清除</div>
            </el-tooltip>
            <el-tooltip content="查询更多条件" placement="top">
              <div class="condition" @click="more">
                {{ MoreConditions }}
              </div>
            </el-tooltip>
          </div>
        </div>
        <!-- 查询条件 -->
        <el-form :model="queryData">
          <div
            ref="queryCriteria1"
            class="HeadInputbox"
            :class="{ HeadInputbox1: judge, active: isActive }"
          >
            <div class="item_right">
              <div class="singleinp wtlTimeBox">
                <div class="singleinpleft wtlTimeLabel">合包时间:</div>
                <div class="wtl_timeStyle">
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd"
                    v-model="queryData_hb.startPackingTime"
                    type="date"
                    placeholder="选择日期"
                  ></el-date-picker>
                  <div>至</div>
                  <el-date-picker
                    class="input"
                    value-format="yyyy-MM-dd"
                    v-model="queryData_hb.endPackingTime"
                    type="date"
                    placeholder="选择日期"
                    @change="queryBtn_ok1"
                  ></el-date-picker>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">打包批次号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="billPackNumbers"
                    placeholder="请输入"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">合包单号:</div>
                <div class="singleinpright">
                  <el-input
                    class="queryInput"
                    clearable
                    v-model="queryData_hb.billClosePackageNumber"
                    placeholder="请输入"
                  >
                  </el-input>
                </div>
              </div>
              <div class="singleinp">
                <div class="singleinpleft">当前仓库:</div>
                <div class="singleinpright">
                  <el-select
                    class="queryInput"
                    v-model="queryData_hb.currentStorageId"
                    filterable
                    placeholder="请选择"
                    @change="queryBtn_ok1"
                  >
                    <el-option
                      v-for="item in storageArr"
                      :key="item.id"
                      :label="item.storageName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </el-form>
        <!-- 表格 -->
        <el-table
          ref="mytable1"
          :height="tableHeigth"
          :data="tableData1"
          :header-cell-style="{ background: '#f0f0f0' }"
          border
          show-summary
          :summary-method="getSummaries1"
          @selection-change="handleSelectionChange"
        >
          <el-table-column
            fixed="left"
            align="center"
            label="序号"
            type="index"
            width="55"
          >
          </el-table-column>
          <el-table-column
            fixed="left"
            align="center"
            type="selection"
            width="55"
          >
          </el-table-column>
          <template v-for="(item, index) in myTableHeard1">
            <el-table-column
              :key="index"
              :label="item.name"
              :prop="item.field"
              align="center"
              :show-overflow-tooltip="true"
              sortable
              :min-width="item.width"
              :data-isTotal="item.isTotal"
              v-if="item && item.isShow"
            >
            </el-table-column>
          </template>
        </el-table>
        <paging
          :pageNum="pageStart"
          :total="pageCount"
          :sizeList="sizeList"
          :size="pageTotal"
          @handleSizeChange="HSC_XJ"
          @handleCurrentChange="HCC_XJ"
        >
        </paging>

        <sorttable
          :show_sortTableHeard="show_sortTableHeard1"
          :allCheck="allCheck1"
          :sortName="sortName1"
          :tabelHeadeTitle="tabelHeadeTitle1"
          :originalTabelHeadeTitle="originalTabelHeadeTitle1"
          :selectLength="selectLength1"
          @cancelSortHeard="cancelSortHeard1"
          @confirmOk="confirmOk1"
          @checktab="checktab1"
          @checkAll="checkAll1"
          @closeDias="closeDias1"
        ></sorttable>

        <!-- 合包出库 -->
        <el-dialog
          title="合包出库"
          :visible.sync="heBaoChuKu_show"
          append-to-body
          width="40%"
          center
          @closed="closeBtn_ck"
        >
          <div class="diaBox">
            <h3>已选出库打包单数量: {{ singleData.length }}个</h3>
            <div class="textareaBox">
              <div class="labelClass">备注:</div>
              <el-input
                class="textareaInput"
                type="textarea"
                v-model="heBaoChuKuData.common"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="heBaoChuKuConfirm"
              >确认出库</el-button
            >
          </div>
        </el-dialog>

        <!-- 合包入库 -->
        <el-dialog
          title="合包入库"
          :visible.sync="heBaoRuKu_show"
          append-to-body
          width="40%"
          center
          @closed="closeBtn_rk"
        >
          <div class="diaBox">
            <h3>已选入库打包单数量: {{ singleData.length }}个</h3>
            <div class="textareaBox">
              <div class="labelClass">
                <span style="color: #f00">*</span>入库仓库:
              </div>
              <el-select
                class="queryInput"
                v-model="heBaoRuKuData.storageId"
                filterable
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in storageArr"
                  :key="item.id"
                  :label="item.storageName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div class="textareaBox">
              <div class="labelClass">备注:</div>
              <el-input
                class="textareaInput"
                type="textarea"
                v-model="heBaoRuKuData.comment"
                placeholder="请输入"
              ></el-input>
            </div>
          </div>

          <div slot="footer" class="dialog-footer">
            <el-button type="primary" @click="heBaoRuKuConfirm"
              >确认入库</el-button
            >
          </div>
        </el-dialog>

        <!-- 上传模板 -->
        <el-dialog
          title="上传编辑好的导出模板"
          :visible.sync="show_upload"
          append-to-body
          width="40%"
          center
        >
          <el-upload
            :action="uploadUrl"
            :show-file-list="true"
            :file-list="fileList"
            :limit="1"
            :http-request="uploadSectionFile"
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">上传模板</div>
          </el-upload>

          <div slot="footer" class="dialog-footer">
            <el-button @click="show_upload = false">关 闭</el-button>
          </div>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
    <!-- 查询条件框 -->
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import sorttable from "@/components/sortTemplate.vue";
import draggable from "vuedraggable"; //拖拽组件
import paging from "@/components/pagings.vue";

export default {
  inject: ["reload"],
  components: { draggable, paging, sorttable },
  data() {
    return {
      activeName: "first",
      number1: "",
      billPackNumbers: "",
      isActive: false, //  输入框绑定动态class
      judge: true, //  输入框绑定动态class
      MoreConditions: "更多条件", //  输入框绑定动态class
      queryData_hb: {
        startPackingTime: "",
        endPackingTime: "",
        billClosePackageNumber: "",
        billPackNumbers: [],
        currentStorageId: "1",
        packingStorageId: "",
      },
      queryData: {
        applyStartTime: "",
        applyEndTime: "",
        memberId: "",
        nickname: "",
        storageId: "1",
      },
      time_first: [],
      time_second: [],
      singleData: [],
      pageNum: 1, //  分页配置
      total: 0,
      sizeList: [10, 20, 50, 100, 200],
      size: 50,
      pageStart: 1,
      pageCount: 0,
      pageTotal: 50,
      tableBData: [],
      resultlist: [],
      downframeList: [
        {
          courierNumber: "",
          memberId: "",
          nickname: "",
          packageId: "",
          storageName: "",
        },
      ],
      applyPackNumber: "",

      OffTheShelf: 5664,
      dialogTableVisible: false,
      cancel: false,
      options: [
        {
          name: "a",
          val: 0,
        },
        {
          name: "b",
          val: 1,
        },
      ],

      show_sortTableHeard: false, //显示弹窗
      show_sortTableHeard1: false, //显示弹窗
      show_upload: false, //显示上传弹窗
      myTableHeard: [], //我的表头
      originalTabelHeadeTitle: [],
      originalTabelHeadeTitle1: [],
      myTableHeard1: [], //我的表头
      selectLength: 0, //显示的长度
      selectLength1: 0, //显示的长度
      allCheck: false, //全选
      allCheck1: false, //全选
      tableHeigth: "50vh",
      tabelHeadeTitle: [
        {
          name: "合包单号",
          field: "number",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包数量",
          field: "packCount",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: true,
          isSort: true,
        },
        {
          name: "打包批次号",
          field: "billPackNumber",
          width: "170",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包状态",
          field: "packStatusShow",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "在库状态",
          field: "stockStatusShow",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "当前仓库",
          field: "currentStorageName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包仓库",
          field: "packingStorageName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包时间",
          field: "packingTime",
          width: "160",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包人",
          field: "packingUserName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tabelHeadeTitle1: [
        {
          name: "合包单号",
          field: "number",
          width: "140",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "打包批次号",
          field: "billPackNumber",
          width: "165",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },

        {
          name: "合包状态",
          field: "packStatusShow",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员号",
          field: "memberId",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "会员名称",
          field: "memberName",
          width: "100",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "当前仓库",
          field: "currentStorageName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包仓库",
          field: "packingStorageName",
          width: "120",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包时间",
          field: "packingTime",
          width: "170",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
        {
          name: "合包人",
          field: "packingUserName",
          width: "80",
          sort: 1,
          isShow: true,
          isTotal: false,
          isSort: true,
        },
      ],
      tableData: [],
      tableData1: [],
      totalArr: [], //需要合计的字段
      totalArr1: [], //需要合计的字段
      className: "", //导出需要
      fieldName: [], //下载字段名
      head: [], //下载表头字段
      fileList: [],
      uploadUrl: "", //上传路径
      sortName: "模板名", //排序名
      sortName1: "模板名", //排序名
      pageGroupName: "PackageList", //页面标识

      storageArr: [],
      chukuIds: [],
      ruKuIds: [],
      heBaoChuKu_show: false, //合包出库弹窗
      heBaoRuKu_show: false, //合包入库弹窗
      heBaoChuKuData: {
        common: "",
      },
      heBaoRuKuData: {
        comment: "",
        billClosePackageIds: "",
        storageId: "",
      },
    };
  },
  updated() {
    if (this.show_sortTableHeard) {
      // 排序表头
      let arr = this.tabelHeadeTitle;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle = arr;
    }
    if (this.show_sortTableHeard1) {
      // 排序表头
      let arr = this.tabelHeadeTitle1;
      for (var i = 0; i < arr.length; i++) {
        arr[i].sort = i + 1;
      }
      this.tabelHeadeTitle1 = arr;
    }
  },
  created() {
    this.uploadUrl = this.common.uploadUrl;
    if (this.originalTabelHeadeTitle.length == 0) {
      this.originalTabelHeadeTitle = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle)
      );
    }
    if (this.originalTabelHeadeTitle1.length == 0) {
      this.originalTabelHeadeTitle1 = JSON.parse(
        JSON.stringify(this.tabelHeadeTitle1)
      );
    }
    this.getGroupFindFieldSort(); //获取用户自定义表头
    this.queryData_hb.endPackingTime = tools.getCurrentDate(); //今天的时间;
    this.queryData_hb.startPackingTime = tools.getFlexDate(-10); //10天前的时间
  },
  mounted() {
    this.getStorageIdArr();
  },
  watch: {},
  methods: {
    rowclick(row, event, column) {
      this.$refs.mytable.toggleRowSelection(row);
    },
    // 仓库列表
    getStorageIdArr() {
      Api.getStorageList().then((res) => {
        this.storageArr = res.data.result || [];
        if (this.storageArr.length > 0) {
          this.queryData_hb.currentStorageId = this.storageArr[0].id;
          this.getData();
        }
      });
    },
    //输入框绑定动态class
    more() {
      this.isActive = !this.isActive;
      this.judge = !this.judge;
      this.fetTableHeight();
      if (this) {
        this.MoreConditions = "收起条件";
      } else {
        this.MoreConditions = "更多条件";
      }
    },
    // 列表查询按钮
    queryBtn_ok() {
      //  查询按钮
      let nidlist = new Array();
      nidlist = tools.getOrderNum(this.billPackNumbers);
      this.queryData_hb.billPackNumbers = nidlist;
      this.pageNum = 1;
      this.getData();
    },
    // 明细查询按钮
    queryBtn_ok1() {
      //  查询按钮
      let nidlist = new Array();
      nidlist = tools.getOrderNum(this.billPackNumbers);
      this.queryData_hb.billPackNumbers = nidlist;
      this.pageStart = 1;
      this.getData_mingxi();
    },

    // 明细列表
    getData_mingxi() {
      let that = this;
      let endPackingTime = "";
      let startPackingTime = "";
      if (that.queryData_hb.endPackingTime) {
        endPackingTime = that.queryData_hb.endPackingTime + " 23:59:59";
      }
      if (that.queryData_hb.startPackingTime) {
        startPackingTime = that.queryData_hb.startPackingTime + " 00:00:00";
      }

      let param = {
        billClosePackageNumber: this.queryData_hb.billClosePackageNumber || "",
        currentStorageId: this.queryData_hb.currentStorageId || "",
        packingStorageId: this.queryData_hb.packingStorageId || "",
        billPackNumbers: this.queryData_hb.billPackNumbers || [],
        startPackingTime: startPackingTime || "",
        endPackingTime: endPackingTime || "",
        pageStart: this.pageStart,
        pageTotal: this.pageTotal,
      };
      Api.getBillClosePackageRecordListV2(param).then((res) => {
        this.className = res.data.result.className || "";
        this.tableData1 = res.data.result.data || [];
        this.pageCount = res.data.result.pageCount || 0;
      });
    },

    // 合包列表
    getData() {
      let that = this;
      let endPackingTime = "";
      let startPackingTime = "";
      if (that.queryData_hb.endPackingTime) {
        endPackingTime = that.queryData_hb.endPackingTime + " 23:59:59";
      }
      if (that.queryData_hb.startPackingTime) {
        startPackingTime = that.queryData_hb.startPackingTime + " 00:00:00";
      }

      let param = {
        billClosePackageNumber: this.queryData_hb.billClosePackageNumber || "",
        currentStorageId: this.queryData_hb.currentStorageId || "",
        packingStorageId: this.queryData_hb.packingStorageId || "",
        billPackNumbers: this.queryData_hb.billPackNumbers || [],
        startPackingTime: startPackingTime || "",
        endPackingTime: endPackingTime || "",
        pageStart: this.pageNum,
        pageTotal: this.size,
      };

      Api.getBillClosePackageListV2(param).then((res) => {
        if (res.data.status === "success") {
          this.className = res.data.result.className || "";
          this.tableData = res.data.result.data || [];
          this.total = res.data.result.pageCount || 0;
          this.fetTableHeight();
        }
      });
    },

    myGetDownframeDetail(number, id) {
      Api.getDownframeDetail({
        applyPackId: id,
        courierNumber: number,
      }).then((res) => {
        if (res.data.status === "success") {
          this.downframeList = res.data.result.noDownframeList || [];
          this.resultlist = res.data.result || [];
        } else {
          this.$message.error(res.data.message);
        }
      });
    },

    // 新建合包
    addHeBao() {
      this.$router.push("/pages/PackageListAdd");
    },
    // 修改合包
    editBtns() {
      if (this.singleData.length < 1) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      if (this.singleData.length != 1) {
        this.$message.warning("一次只能修改一条数据");
        return false;
      }
      this.$router.push({
        path: "/pages/PackageListEdit",
        query: {
          id: this.singleData[0].billClosePackageId,
        },
      });
    },
    // 合包出库确定按钮
    heBaoChuKuConfirm() {
      let param = {
        billClosePackageIds: this.chukuIds || [],
        comment: this.heBaoChuKuData.common || "",
      };
      param.sign = tools.getSign(param);
      Api.outStockV2(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.heBaoChuKu_show = false;
        this.getData();
      });
    },
    // 合包入库确定按钮
    heBaoRuKuConfirm() {
      if (!this.heBaoRuKuData.storageId) {
        this.$message.warning("入库仓库不能为空");
        return false;
      }
      let param = {
        billClosePackageIds: this.ruKuIds || [],
        storageId: this.heBaoRuKuData.storageId || "",
        comment: this.heBaoRuKuData.comment || "",
      };
      // param.sign = tools.getSign(param);
      Api.inStockV2(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.heBaoRuKu_show = false;
        this.getData();
      });
    },
    closeBtn_ck() {
      this.heBaoChuKuData.common = "";
      this.chukuIds = [];
    },
    closeBtn_rk() {
      this.heBaoRuKuData = {
        comment: "",
        billClosePackageIds: "",
        storageId: "",
      };
      this.ruKuIds = [];
    },
    // 合包出库
    heBaoChuKu() {
      if (this.singleData.length < 1) {
        this.$message.warning("请选择至少一条数据进行出库");
        return false;
      }
      let ids = [];
      for (var i = 0; i < this.singleData.length; i++) {
        let item = this.singleData[i];
        ids.push(item.billClosePackageId);
        if (item.packStatus === "yi_chai_bao:pack_status") {
          this.$message.warning("所选包裹中有已拆包的数据,不能出库");
          return false;
        }
      }
      this.chukuIds = ids;
      this.heBaoChuKu_show = true;
    },
    // 合包出入库
    heBaoRuKu() {
      if (this.singleData.length < 1) {
        this.$message.warning("请选择至少一条数据进行入库");
        return false;
      }
      let ids = [];
      for (var i = 0; i < this.singleData.length; i++) {
        let item = this.singleData[i];
        ids.push(item.billClosePackageId);
        if (item.packStatus === "yi_chai_bao:pack_status") {
          this.$message.warning("所选包裹中有已拆包的数据,不能入库");
          return false;
        }
      }
      this.ruKuIds = ids;
      this.heBaoRuKu_show = true;
    },
    // 拆包
    chaiBao() {
      if (this.singleData.length < 1) {
        this.$message.warning("请选择至少一条数据");
        return false;
      }
      this.$confirm("确定要把所选数据拆包?", "提示:")
        .then(() => {
          let ids = [];
          for (var i = 0; i < this.singleData.length; i++) {
            let item = this.singleData[i];
            ids.push(item.billClosePackageId);
            if (item.packStatus === "yi_chai_bao:pack_status") {
              this.$message.warning("所选包裹中有已拆包的数据,不能再拆包");
              return false;
            }
          }

          Api.openClosePackage({ billClosePackageIds: ids }).then((res) => {
            this.$message.success(res.data.message || "拆包成功");
            this.getData();
          });
        })
        .catch(() => {});
    },

    //  重置
    resetFeild() {
      this.queryData = {};
      this.time_first = [];
      this.number1 = "";
    },
    handleSelectionChange(e) {
      //  表格选择事件
      this.singleData = e;
    },
    //分页
    HSC_SJ(val) {
      this.size = val;
      this.getData();
    },
    HCC_SJ(val) {
      this.pageNum = val;
      this.getData();
    },
    // 明细分页
    HSC_XJ(val) {
      this.pageTotal = val;
      this.getData_mingxi();
    },
    HCC_XJ(val) {
      this.pageStart = val;
      this.getData_mingxi();
    },
    //导出操作
    handleCommand_dc(command) {
      if (command == "dc") {
        // 导出
        this.exportBtn();
      } else if (command == "dcmb") {
        // 下载模板
        this.exportTemplateDownload();
      } else if (command == "scmb") {
        // 上传模板
        this.upLoadBtn();
      }
    },
    // 上传导出模板
    uploadSectionFile(param) {
      //console.log(param);
      const file = param.file,
        fileName = file.name;

      // 根据后台需求数据格式
      const form = new FormData();
      // 文件对象
      form.append("file", file);
      // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
      form.append("fileName", fileName);
      let signArr = [
        {
          key: "fileName",
          val: fileName,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      form.append("sign", sign);
      // 项目封装的请求方法，下面做简单介绍
      Api.uploadFile(form)
        .then((res) => {
          if (res.data.status === "success") {
            let fileName = res.data.result.fileName;

            let signArr = [
              {
                key: "groupName",
                val: this.pageGroupName,
              },
              {
                key: "templateName",
                val: this.sortName,
              },
              {
                key: "fileName",
                val: fileName,
              },
            ];
            let sign_ = tools.getMD5Sign(signArr);
            Api.saveExportTemplate({
              groupName: this.pageGroupName,
              templateName: this.sortName,
              fileName: fileName,
              sign: sign_,
            }).then((res) => {
              this.$message.success(res.data.message || "更新成功");
              this.show_upload = false;
            });
          }
        })
        .catch((err) => {});
    },

    // 下载用户导出模板
    exportTemplateDownload() {
      var fieldName = this.fieldName;
      var head = this.head;
      if (head.length == 0) {
        let fieldNames = [];
        let heads = [];
        if (this.pageGroupName == "PackageList") {
          for (var i = 0; i < this.myTableHeard.length; i++) {
            if (this.myTableHeard[i].isShow) {
              fieldNames.push(this.myTableHeard[i].field);
              heads.push(this.myTableHeard[i].name);
            }
          }
        }
        if (this.pageGroupName == "PackageList1") {
          for (var i = 0; i < this.myTableHeard1.length; i++) {
            if (this.myTableHeard1[i].isShow) {
              fieldNames.push(this.myTableHeard1[i].field);
              heads.push(this.myTableHeard1[i].name);
            }
          }
        }
        fieldName = fieldNames;
        head = heads;
      }
      //加密数组格式
      let signArr = [
        {
          key: "fieldName",
          val: fieldName,
        },
        {
          key: "head",
          val: head,
        },
      ];
      let sign = tools.getMD5Sign(signArr);
      let param = {
        fieldName: fieldName,
        head: head,
        sign: sign,
      };
      Api.exportTemplateDownload(param).then((res) => {
        let downloadurl = res.data.result.fileUrl || "";
        //下载(这里其实是直接访问)
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },

    // 真正导出
    templateDataExport(customizedExportId) {
      let that = this;
      let datas;
      if (this.pageGroupName == "PackageList") {
        datas = that.tableData;
      } else {
        datas = that.tableData1;
      }
      let parm = {
        customizedExportId: customizedExportId, //模板id
        className: that.className,
        data: datas,
      };
      let signarr = tools.getSignArr(parm);
      let signs_e = tools.getMD5Sign(signarr);
      Api.templateDataExport({
        customizedExportId: customizedExportId,
        className: that.className,
        data: JSON.stringify(datas),
        sign: signs_e,
      }).then((res) => {
        //下载(这里其实是直接访问)
        let downloadurl = res.data.result.fileUrl;
        var a = document.createElement("a");
        a.setAttribute("href", downloadurl);
        a.setAttribute("target", "_blank");
        a.click();
      });
    },
    // 上传按钮
    upLoadBtn() {
      this.show_upload = true;
    },
    // 点击导出按钮
    exportBtn() {
      let that = this;
      let fieldName = [];
      let head = [];
      if (this.pageGroupName == "PackageList") {
        for (var i = 0; i < this.myTableHeard.length; i++) {
          if (this.myTableHeard[i].isShow) {
            fieldName.push(this.myTableHeard[i].field);
            head.push(this.myTableHeard[i].name);
          }
        }
      }
      if (this.pageGroupName == "PackageList1") {
        for (var i = 0; i < this.myTableHeard1.length; i++) {
          if (this.myTableHeard1[i].isShow) {
            fieldName.push(this.myTableHeard1[i].field);
            head.push(this.myTableHeard1[i].name);
          }
        }
      }
      this.fieldName = fieldName;
      this.head = head;

      // 1,先获取用户导出模板
      Api.getUserExportTemplate({
        groupName: this.pageGroupName,
      }).then((res) => {
        if (res.data.result && res.data.result[0].customizedExportId) {
          // 有自定义模板

          let customizedExportId = res.data.result[0].customizedExportId;
          that.templateDataExport(customizedExportId);
        } else {
          //没有自定义模板,就是下载一个
          tools.dynamicExport(
            this.myTableHeard,
            this.className,
            this.tableData,
            "其他支出列表"
          );
        }
      });
    },
    // ===============================================导出部分=结束======

    /**
     * ******************************计算表格高度**开始*************************
     */
    // 重置table高度
    resetHeight() {
      return new Promise((resolve, reject) => {
        this.tableHeigth = 0;
        resolve();
      });
    },
    // 设置table高度
    fetTableHeight() {
      this.resetHeight().then((res) => {
        this.tableHeigth = this.getHeight();
        this.$nextTick(() => {
          this.$refs.mytable.doLayout();
        });
      });
    },
    // 获取表格高度
    getHeight() {
      let windonHeight = document.body.clientHeight;
      let queryHeigth;
      let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
      if (this.judge) {
        queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
        if (this.activeName === "second") {
          queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
        }
      } else {
        queryHeigth = 50;
        if (this.activeName === "second") {
          queryHeigth = 50;
        }
      }
      let pagsHeigth = 60; //分页组件
      var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 80;
      if (this.activeName === "second") {
        otherHeight = queryHeigth + btnListHeight + pagsHeigth + 100;
      }
      return Math.floor(windonHeight - otherHeight) || "auto"; //
    },
    /**
     * ******************************计算表格高度**结束*************************
     */

    // 刷新页面==>
    reload_s() {
      this.reload(); //App.Vue里面的方法
    },

    //顶部标签页切换
    handleClick() {
      //console.log(this.activeName);
      // this.resetFeild();
      this.number1 = "";
      if (this.activeName == "second") {
        this.pageGroupName = "PackageList1";
        this.SignInRecord();
        this.getData_mingxi();
      } else {
        this.pageGroupName = "PackageList";
        this.getGroupFindFieldSort();
        this.getData();
      }
      this.singleData = [];
    },
    //签收记录表头获取
    SignInRecord() {
      let that = this;
      //console.log(this.pageGroupName);
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard1 = JSON.parse(arr[0].sortObject);
            this.sortName1 = arr[0].sortName;
          }
        } else {
          that.myTableHeard1 = that.tabelHeadeTitle1;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard1.length; i++) {
          if (that.myTableHeard1[i].isTotal) {
            totalArr.push(that.myTableHeard1[i].field);
          }
        }
        that.totalArr1 = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 获取自定义的排序,没有就用默认的
    getGroupFindFieldSort() {
      let that = this;
      Api.getGroupFindFieldSort({
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
      }).then((res) => {
        let arr = res.data.result || [];
        if (arr.length > 0) {
          if (arr[0].sortObject) {
            that.myTableHeard = JSON.parse(arr[0].sortObject);
            this.sortName = arr[0].sortName;
          }
        } else {
          that.myTableHeard = that.tabelHeadeTitle;
        }
        let totalArr = [];
        //提取需要合计的字段
        for (var i = 0; i < that.myTableHeard.length; i++) {
          if (that.myTableHeard[i].isTotal) {
            totalArr.push(that.myTableHeard[i].field);
          }
        }
        that.totalArr = totalArr;
      });

      that.$nextTick(() => {
        that.$refs.mytable.doLayout();
      });
    },
    // 设置模板按钮
    myModel() {
      let that = this;
      that.show_sortTableHeard = true;
      that.tabelHeadeTitle = JSON.parse(JSON.stringify(that.myTableHeard));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //设置模板按钮(签收记录)
    myModel1() {
      let that = this;
      that.show_sortTableHeard1 = true;
      that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength == that.tabelHeadeTitle1.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },

    // 排序==>取消按钮
    cancelSortHeard() {
      this.show_sortTableHeard = false;
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
    },
    // 排序==>取消按钮(签收记录)
    cancelSortHeard1() {
      this.show_sortTableHeard1 = false;
      this.$nextTick(() => {
        this.$refs.mytable1.doLayout();
      });
    },
    // 排序==>确认按钮
    confirmOk(e) {
      this.tabelHeadeTitle = e;
      this.myTableHeard = this.tabelHeadeTitle;
      let sortObject = this.myTableHeard;
      let signs = [
        {
          key: "sortName",
          val: this.sortName, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      this.show_sortTableHeard = false;
    },
    // 点击某一项
    checktab(idx, e) {
      let that = this;
      that.tabelHeadeTitle[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
      if (selectLength == that.tabelHeadeTitle.length) {
        that.allCheck = true;
      } else {
        that.allCheck = false;
      }
    },
    //全选
    checkAll(e) {
      let that = this;
      this.allCheck = e;
      for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
        this.tabelHeadeTitle[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
        if (that.tabelHeadeTitle[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength = selectLength;
    },
    //关闭弹窗
    closeDias(e) {
      this.show_sortTableHeard = e;
    },
    //关闭弹窗
    closeDias1(e) {
      this.show_sortTableHeard1 = e;
    },
    // 合计
    getSummaries(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr.length; i++) {
          if (column.property == that.totalArr[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable.doLayout();
      });
      return sums;
    },
    // 排序==>确认按钮(签收记录)
    confirmOk1(e) {
      this.tabelHeadeTitle1 = e;

      this.myTableHeard1 = this.tabelHeadeTitle1;
      let sortObject = this.myTableHeard1;
      let signs = [
        {
          key: "sortName",
          val: this.sortName1, //模板名称
        },
        {
          key: "groupName",
          val: this.pageGroupName, //页面标识
        },
        {
          key: "sortObject",
          val: JSON.stringify(sortObject),
        },
      ];

      let sign = tools.getMD5Sign(signs);

      let param = {
        // apiPath: apiList.api_packageList, //综合查询接口路径
        groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
        sortName: this.sortName1, //综合查询接口路径
        sortObject: JSON.stringify(sortObject),
        sign: sign,
      };
      Api.pushFindFieldSort(param).then((res) => {
        this.$message.success(res.data.message || "操作成功");
        this.getGroupFindFieldSort();
      });
      this.show_sortTableHeard1 = false; //关闭弹窗
      this.$nextTick(() => {
        this.$refs.mytable1.doLayout();
      });
      this.show_sortTableHeard1 = false;
    },
    // 点击某一项
    checktab1(idx, e) {
      let that = this;
      that.tabelHeadeTitle1[idx].isShow = e;
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
      if (selectLength == that.tabelHeadeTitle1.length) {
        that.allCheck1 = true;
      } else {
        that.allCheck1 = false;
      }
    },
    //全选
    checkAll1(e) {
      let that = this;
      this.allCheck1 = e;
      for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
        this.tabelHeadeTitle1[j].isShow = e;
      }
      let selectLength = 0;
      for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
        if (that.tabelHeadeTitle1[i].isShow) {
          selectLength++;
        }
      }
      that.selectLength1 = selectLength;
    },
    // 合计
    getSummaries1(param) {
      let that = this;
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        for (var i = 0; i < that.totalArr1.length; i++) {
          if (column.property == that.totalArr1[i]) {
            if (!values.every((value) => isNaN(value))) {
              sums[index] = values.reduce((prev, curr) => {
                const value = Number(curr);
                if (!isNaN(value)) {
                  return tools.mathToFixed(tools.add(prev, curr), 2);
                } else {
                  return prev;
                }
              }, 0);
              sums[index] += "";
            } else {
              sums[index] = "---";
            }
          }
        }
      });
      this.$nextTick(() => {
        this.$refs.mytable1.doLayout();
      });
      return sums;
    },

    //分页
    handleSizeChange(val) {
      this.size = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
.tableBox {
  height: calc(100% - 260px);
}

.title {
  padding: 20px 0;
  box-sizing: border-box;
}

.inpustyle {
  width: 80%;
}

.Bottomtotal {
  margin-top: 30px;
  font-weight: 600;
}

.tableBox {
  height: calc(100% - 260px);
}

.colorStyle {
  color: #f00;
}

.mdTitName {
  height: 24px;
  border: 1px solid #d7d7d7;
  text-align: center;
  min-width: 100px;
}

.queryItem1 {
  width: 66%;
  margin-bottom: 16px;
}

.dialog-footer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tips {
  font-size: 16px;
  background-color: #fff;
  padding: 20px;
  .msg {
    margin-bottom: 6px;
  }
  .numbox {
    display: flex;
    div:nth-child(2) {
      background-color: #e0e0e0;
      padding: 0 6px;
      border: 1px #5d5d5d solid;
    }
  }
}

.tableB {
  margin: 6px 0;
  padding: 10px 20px;
  background-color: #fff;
}

.QXXJ_Container {
  /deep/ .el-dialog__body {
    padding: 0 !important;
    background-color: #f0f0f0;
  }
}

.endFooter {
  background-color: #fff;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: bold;
}

.diaBox {
  text-align: center;

  .textareaBox {
    display: flex;
    margin-bottom: 20px;
    .labelClass {
      width: 80px;
      text-align: right;
      margin-right: 10px;
      height: 40px;
      line-height: 40px;
    }
    // .textareaInput {
    //   flex: 1;
    // }
  }
}
</style>
